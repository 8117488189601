.rcs-custom-scroll {
    @apply h-full max-h-inherit;

    .rcs-outer-container {
        @apply h-full max-h-inherit;

        .rcs-inner-container {
            @apply h-full max-h-inherit;
        }
    }

    .rcs-inner-handle {
        @apply bg-blue;
    }
}