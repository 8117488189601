.fancybox__nav .carousel__button.is-prev {
    left: 0;
}

.fancybox__nav .carousel__button.is-next {
    right: 0;
}

.fancybox__nav .carousel__button.is-prev,
.fancybox__nav .carousel__button.is-next {
    height: 80%;
    width: 70px;
    border-radius: 0;
    transition: background-color 0.5s;
}

.fancybox__nav .carousel__button.is-next:hover,
.fancybox__nav .carousel__button.is-prev:hover {
    background-color: rgba(192, 192, 192, 0.1);
    transition: background-color 0.5s;
}

.carousel__viewport {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 35px;
    margin-right: 35px;
}

.carousel__track {
    margin-left: auto;
    margin-right: auto;
}

.carousel__slide {
    padding: 0;
    width: var(--carousel-slide-width, 15%);
}

.carousel__file {
    & .carousel__slide {
        margin-left: 5px;
    }

    & .carousel__track {
        display: flex;
    }

    & .file_box {
        display: flex;
        flex-direction: column;

        & svg {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.fileName {
    font-size: 12px;
    text-align: center;
    height: 20px;
    overflow: hidden;
}

.carousel__button.is-next {
    width: 48px;
    right: 0;
}

.carousel__button.is-prev {
    left: 0;
}

.carousel__dots {
    position: absolute;
    height: 0;
}

.carousel__photo .carousel__slide {
    align-self: center;
    margin-left: 10px;
}

.carousel__photo .carousel__track {
    height: 54px;
}

.carousel__slide_photo {
    width: 48px;
    height: 48px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 3px;
}

.select__photo {
    border: 3px solid rgba(63, 140, 254, 0.4);
    // width: 56px;
    border-radius: 6px;
    scale: 1;
}

.select__photo .carousel__slide_photo {
    width: 50px;
}

.uploadPhoto {
    border: 3px solid red;
    border-radius: 6px;
}

.messageArea textarea {
    box-shadow: none !important;
}
