@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

@layer components {
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        border-color: #1890ff;
        --tw-ring-color: #1890ff;
    }

    [type='text']:hover:not(:focus):not(:disabled),
    [type='email']:hover:not(:focus),
    [type='url']:hover:not(:focus),
    [type='password']:hover:not(:focus),
    [type='number']:hover:not(:focus),
    [type='date']:hover:not(:focus),
    [type='datetime-local']:hover:not(:focus),
    [type='month']:hover:not(:focus),
    [type='search']:hover:not(:focus),
    [type='tel']:hover:not(:focus),
    [type='time']:hover:not(:focus),
    [type='week']:hover:not(:focus),
    [multiple]:hover:not(:focus),
    textarea:hover:not(:focus),
    select:hover:not(:focus) {
        border-color: #b3b3b3;
    }

    [type='checkbox'],
    [type='radio'] {
        color: #1890ff;
    }

    [type='checkbox']:focus,
    [type='radio']:focus {
        --tw-ring-color: #1890ff;
    }

    input:disabled, input:hover:disabled {
        @apply border-gray-70 bg-gray-10;
    }
}

// Additional styles
@import 'additional-styles/utility-patterns';
@import 'additional-styles/range-slider';
@import 'additional-styles/toggle-switch';
@import 'additional-styles/theme';
@import 'additional-styles/sliding-pane';
//react-select
@import 'additional-styles/react-select';
//datepicker
@import 'react-datepicker/dist/react-datepicker.css';
@import 'additional-styles/react-datepicker';
//infinite-scroll
@import 'additional-styles/infinite-scroll';
//notifications
@import 'react-toastify/dist/ReactToastify.css';
//fancybox
@import 'additional-styles/fancybox.scss';
//popup
@import 'additional-styles/popup.scss';
// svg hover
@import 'additional-styles/qual-styles.scss';
//custom scroll
@import "react-custom-scroll/dist/customScroll.css";
@import "additional-styles/custom-scroll";

@tailwind utilities;

@layer utilities {
    .border-spacing-0 {
        border-spacing: 0;
    }
}
