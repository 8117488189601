.svg_box {
    position: relative;
    margin-left: auto;
    padding-top: 8px;
    width: 15px;
    height: 15px;
}

.svg_hover {
    & span {
        white-space: nowrap;
    }

    & .react-datepicker-wrapper {
        width: 0;
        margin-left: auto;
        margin-right: 8px;
    }

    & .react-datepicker__input-container {
        width: 0;
    }
}

.path_hover:hover {
    & svg path {
        fill: #1890ff;
    }

    & .svg_box {
        cursor: pointer;
    }
}

.qualifiactions_filter {
    & .filter__indicators {
        height: 2.5rem;
    }

    & .filter__value-container {
        height: 2rem;
    }

    & .qual-name-select .filter__value-container {
        flex-wrap: nowrap;

        & .filter__multi-value {
            min-width: 60px;
        }

        .filter__placeholder {
            text-transform: none;
        }
    }

    & .depart-select .filter__value-container {
        flex-wrap: nowrap;

        & .filter__multi-value {
            min-width: 80px;
        }
    }

    & .status-select .filter__value-container {
        flex-wrap: nowrap;

        & .filter__multi-value {
            min-width: 100px;
        }
    }
}
